<template>
  <div>
    <b-card
    >
      <div>
        <b-row>
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>
        </b-row>
        <b-form
            @submit.prevent="searchByCondition"
            @reset.prevent="resetCondition"
        >
          <b-card>
            <b-row>
              <b-col
                  cols="6"
                  md="3"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="开票编号:"
                    label-for="invoice_no"
                    label-size="sm"
                >
                  <b-form-input
                      id="invoice_no"
                      v-model="state.condition.invoiceNo"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="6"
                  md="3"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="店铺团队:"
                    label-for="store_name"
                    label-size="sm"
                >
                  <b-form-input
                      id="store_name"
                      v-model="state.condition.storeName"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="6"
                  md="3"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="客户名称:"
                    label-for="other_company_name"
                    label-size="sm"
                >
                  <b-form-input
                      id="other_company_name"
                      v-model="state.condition.otherCompanyName"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="6"
                  md="3"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="OA编号:"
                    label-for="oa_workflow_no"
                    label-size="sm"
                >
                  <b-form-input
                      id="oa_workflow_no"
                      v-model="state.condition.oaWorkflowNo"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="6"
                  md="3"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="销售类型:"
                    label-for="sales_type"
                    label-size="sm"
                >
                  <v-select
                      id="sales_type"
                      :options="sales_type_select"
                      :clearable="true"
                      v-model = "state.condition.salesType"
                      class="select-size-sm"
                      placeholder="选择销售类型"
                      :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="6"
                  md="3"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="创建人:"
                    label-for="creatorName"
                    label-size="sm"
                >
                  <b-form-input
                      id="creatorName"
                      v-model="state.condition.creatorName"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="6"
                  md="3"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="开票单位:"
                    label-for="our_company_name"
                    label-size="sm"
                >
                  <b-form-input
                      id="our_company_name"
                      v-model="state.condition.ourCompanyName"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="6"
                  md="3"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="审核状态:"
                    label-for="status"
                    label-size="sm"
                >
                  <v-select
                      id="status"
                      :options="getCodeOptions('contract_status')"
                      :clearable="true"
                      v-model = "state.condition.status"
                      class="select-size-sm"
                      placeholder="选择审核状态"
                      :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="6"
                  md="3"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="发票抬头:"
                    label-for="invoice_head"
                    label-size="sm"
                >
                  <b-form-input
                      id="invoice_head"
                      v-model="state.condition.invoiceHead"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="6"
                  md="3"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="关联编号:"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-form-input
                      id="order_no"
                      v-model="state.condition.orderNo"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="12"
                  class=" demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    variant="primary"
                    class="mr-1"
                    @click="add()"
                >
                  新增
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="exportData()"
                >
                  <span class="align-right">导出</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </div>
      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          :fields="tableColumns"
          primary-key="invoice_id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          v-model="table"
      >

        <!-- Columns -->
        <template #cell(invoiceNo)="data">
          <b-link v-if="data.item.status === 0||data.item.status === 8||data.item.status === 10||data.item.status === 11||data.item.status === 3" @click="showEditModal(data.item,false)">
            {{data.item.invoice_no}}
          </b-link>
          <b-link v-else-if="data.item.status === 1||data.item.status === 2" @click="showEditModal(data.item,true)">
            {{data.item.invoice_no}}
          </b-link>
          <br>
          {{data.item.oa_workflow_no}}
        </template>
        <template #cell(salesType)="data">
          {{data.item.sales_type}}
        </template>
        <template #cell(storeName)="data">
          {{data.item.store_name}}
        </template>
        <template #cell(otherCompanyName)="data">
          {{data.item.other_company_name}}
        </template>
        <template #cell(invoice_head)="data">
          {{data.item.invoice_head}}
        </template>
        <template #cell(isTraveler)="data">
          {{getCodeLabel('yesno', data.item.is_traveler)}}
        </template>
        <template #cell(invoiceApplyType)="data">
          {{getCodeLabel('invoice_apply_type', data.item.invoice_apply_type)}}
        </template>
        <template #cell(invoiceAmount)="data">
          {{data.item.invoice_amount}}
        </template>
        <template #cell(ourCompanyName)="data">
          {{data.item.our_company_name}}
        </template>
        <template #cell(creator)="data">
          {{getCodeLabel('user', data.item.creator)}}
        </template>
        <template #cell(remark)="data">
<!--          {{data.item.remark}}-->
          <div :id="`memo_ch-row-${data.item.invoice_id}`">
            {{ data.value.substring(0, 3) }}
            <span v-if="data.value.length > 3"> ...</span>
          </div>
          <b-tooltip
              :target="`memo_ch-row-${data.item.invoice_id}`"
              placement="top"
          >
            {{ data.value }}
          </b-tooltip>
        </template>
        <template #cell(status)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('contract_status',data.item.status)}`"
          >
            {{getCodeLabel('contract_status', data.item.status)}}
          </b-badge>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >
            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>
            <!--      新建      -->
            <b-dropdown-item  @click="showEditModal(data.item,false)" v-if="data.item.status === 0">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>
            <b-dropdown-item @click="deleteApply(data.item)" v-if="data.item.status === 0">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">删除</span>
            </b-dropdown-item>
            <b-dropdown-item @click="changeStatus(data.item,10)" v-if="data.item.status === 0">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">提交审核</span>
            </b-dropdown-item>
            <!--    变更草稿        -->
            <b-dropdown-item  @click="showEditModal(data.item,false)"  v-if="data.item.status === 8">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>
            <b-dropdown-item @click="deleteApply(data.item)" v-if="data.item.status === 8">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">删除</span>
            </b-dropdown-item>
            <b-dropdown-item @click="changeStatus(data.item,10)" v-if="data.item.status === 8">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">提交审核</span>
            </b-dropdown-item>
            <!--      提交      -->
            <b-dropdown-item @click="showEditModal(data.item,false)" v-if="data.item.status === 10">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>
            <b-dropdown-item @click="changeStatus(data.item,11)" v-if="data.item.status === 10&&(approveFlag||currentUserId === data.item.creator)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">驳回</span>
            </b-dropdown-item>
            <b-dropdown-item @click="changeStatus(data.item,1)" v-if="data.item.status === 10&&approveFlag">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">同意</span>
            </b-dropdown-item>
            <!--      审批不通过      -->
            <b-dropdown-item  @click="showEditModal(data.item,false)"  v-if="data.item.status === 11">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>
            <b-dropdown-item @click="deleteApply(data.item)" v-if="data.item.status === 11">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">删除</span>
            </b-dropdown-item>
            <b-dropdown-item @click="changeStatus(data.item,10)" v-if="data.item.status === 11">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">提交审核</span>
            </b-dropdown-item>
            <!--      OA审核中      -->
            <b-dropdown-item  @click="showEditModal(data.item,true)"  v-if="data.item.status === 1">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">查看</span>
            </b-dropdown-item>
            <b-dropdown-item @click="changeStatus(data.item,3)" v-if="data.item.status === 1">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">撤回</span>
            </b-dropdown-item>
            <!--      OA已驳回      -->
            <b-dropdown-item  @click="showEditModal(data.item,false)"  v-if="data.item.status === 3">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>
            <b-dropdown-item @click="deleteApply(data.item)" v-if="data.item.status === 3">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">删除</span>
            </b-dropdown-item>
            <b-dropdown-item @click="changeStatus(data.item,10)" v-if="data.item.status === 3">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">提交审核</span>
            </b-dropdown-item>
            <!--      OA审核通过      -->
            <b-dropdown-item @click="showEditModal(data.item,true)" v-if="data.item.status === 2">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">查看</span>
            </b-dropdown-item>
          </b-dropdown>

        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>
        </b-row>
      </div>
      <!--/ form -->
      <b-modal
          id="loadingModal"
          centered
          hide-header
          no-close-on-backdrop
          no-close-on-esc
          hide-footer
          ref="loadingModal"
      >
        <p class="my-4">正在处理，请勿关闭浏览器!</p>
      </b-modal>

      <b-modal
          id="modal-edit"
          ok-only
          ok-title="取消"
          centered
          size="xl"
          :title="'编辑'"
          ref="editModal"
      >
        <invoice-apply-edit ref="invoiceApplyEdit" :id="id" :view_flag="view_flag" :cancelFlag="true" :status="status" @refesh="refesh" @cancelEdit="cancelEdit"></invoice-apply-edit>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BFormCheckbox, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BModal
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import Ripple from "vue-ripple-directive";
import {ref, onUnmounted, reactive, onMounted, toRefs} from '@vue/composition-api'
import { avatarText, getCode, getCodeColor, getCodeLabel, getCodeOptions } from '@core/utils/filter'
import invoiceApplyListStore from "@/views/apps/invoiceapply/invoiceApplyListStore";
import invoiceApplyList from './invoiceApplyList.js'
import InvoiceApplyEdit from './InvoiceApplyEdit.vue'
import {second} from "@core/utils/dayjs/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import axios from "@/libs/axios";

export default {
  components: {
    BCard,
    BModal,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    InvoiceApplyEdit,
  },
  data() {
    return {

      sales_type_select: [
        {label: '线下', value: '1'},
        {label: '线上', value: '2'}
      ],

      id:0,
      view_flag:true,
      status:99,
    }
  },

  methods: {
    searchByCondition(){
      this.refetchData()
    },
    showEditModal(item,view_flag) {
      this.id = item.invoice_id
      this.view_flag = view_flag
      this.status = item.status
      this.$refs['editModal'].show()
    },
    refesh(){
      this.$refs['editModal'].hide()
      this.refetchData()
    },
    cancelEdit(){
      this.$refs['editModal'].hide()
    },
    resetCondition() {
      this.state.condition = {}
      store.commit('invoiceApplyList/updateCondition', this.state.condition)
      /*this.invoiceNo = ''
      this.storeName = ''
      this.otherCompanyName = ''
      this.oaWorkflowNo = ''
      this.salesType = ''
      this.creatorName = ''
      this.ourCompanyName = ''
      this.status = ''*/
      this.refetchData()
    },
    add: function () {
      this.$router.push({ name: 'apps-invoice-apply-edit'});
    },
    exportData: function () {
      const condition = this.condition
      axios({
            url: '/api/exportexcelfile/exportInvoice',
            method:'get',
            params: {
              invoiceNo: condition.invoiceNo,
              storeName: condition.storeName,
              invoiceHead: condition.invoiceHead,
              otherCompanyName: condition.otherCompanyName,
              oaWorkflowNo:condition.oaWorkflowNo,
              salesType:condition.salesType,
              creatorName:condition.creatorName,
              ourCompanyName:condition.ourCompanyName,
              status:condition.status,
              order_by: this.orderBy,
              order_desc: this.isSortDirDesc === true ? 'desc':'',
            },
            responseType: 'blob',
          }
      ).then(res => {
        let data = new Blob([res.data], { type: 'application/vnd.ms-excel' })   //接收的是blob，若接收的是文件流，需要转化一下
        let filename = "发票申请-"+new Date().getTime();
        /*-------------*/
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = URL.createObjectURL(data);
        link.setAttribute('download',filename+'.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    },
    changeStatus: function (item,status) {
      this.$refs['loadingModal'].show()
      store
          .dispatch('invoiceApplyList/changeStatus', {
            invoiceId: item.invoice_id,
            status:status,
            oldStatus:item.status,
          })
          .then(res => {
            this.$refs['loadingModal'].hide()
            if (res.data.code === 0) {
              this.refetchData()
            } else {
              alert(res.data.data)
            }
          })
    },
    deleteApply: function (item) {
      this.$refs['loadingModal'].show()
      store
          .dispatch('invoiceApplyList/deleteApply', {
            invoiceId: item.invoice_id,
          })
          .then(res => {
            this.$refs['loadingModal'].hide()
            if (res.data.code === 0) {
              this.refetchData()
            } else {
              alert(res.data.data)
            }
          })
    },
  },
  directives: {
    Ripple,
  },
  setup(props) {
    const state = reactive({
      condition: {},
    })
    // Register module
    if (!store.hasModule('invoiceApplyList')) store.registerModule('invoiceApplyList', invoiceApplyListStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('invoiceApplyList')) store.unregisterModule('invoiceApplyList')
    })
    onMounted(() => {
      state.condition = store.getters['invoiceApplyList/getCondition']
    })

    const {
      approveFlag,
      currentUserId,
      searchList,
      table,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,


      /*invoiceNo,
      storeName,
      otherCompanyName,
      oaWorkflowNo,
      salesType,
      creatorName,
      ourCompanyName,
      status,*/
    } = invoiceApplyList(
        {}
    )

    return {
      ...toRefs(state),
      state,
      searchList,
      approveFlag,
      currentUserId,
      table,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      /*invoiceNo,
      storeName,
      otherCompanyName,
      oaWorkflowNo,
      salesType,
      creatorName,
      ourCompanyName,
      status,*/
      // Filter
      avatarText,
      getCodeLabel,
      getCode,
      getCodeColor,
      getCodeOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
