import axios from '@axios'

export default {
  namespaced: true,
  state: {
    condition:{},
  },
  getters: {
    getCondition(state){
      return state.condition
    }
  },
  mutations: {
    updateCondition(state,condition){
      /*if (condition){
        state.condition = condition
      }*/
      state.condition = condition
    }
  },
  actions: {
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/invoiceapply/search', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changeStatus(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/invoiceapply/changeStatus', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteApply(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/invoiceapply/deleteApply', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
