import {ref, watch, computed, reactive} from '@vue/composition-api'
import store from '@/store'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {isEmpty} from "@core/utils/filter";

export default function invoiceApplyList(config) {
  const toast = useToast()
  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'invoiceNo', label: '开票/OA编号' , sortable: true},
    { key: 'salesType', label: '销售类型' , sortable: true},
    { key: 'storeName', label: '店铺团队' , sortable: true},
    { key: 'otherCompanyName', label: '客户名称' , sortable: true},
    { key: 'invoice_head', label: '发票抬头' , sortable: true},
    { key: 'isTraveler', label: '是否散客' , sortable: true},
    { key: 'invoiceApplyType', label: '开票类型' , sortable: true},
    { key: 'invoiceAmount', label: '开票金额' , sortable: true},
    { key: 'ourCompanyName', label: '开票单位' , sortable: true},
    { key: 'creator', label: '创建人' , sortable: true},
    { key: 'remark', label: '备注' , sortable: true},
    { key: 'status', label: '审核状态' , sortable: true},
    { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const orderBy = ref("FIELD(a.status, 0, 8, 10, 11, 1,3,2),a.create_time desc,a.invoice_id desc")
  const isSortDirDesc = ref(false)
  /*const invoiceNo = ref()
  const storeName = ref()
  const otherCompanyName = ref()
  const oaWorkflowNo = ref()
  const salesType = ref()
  const creatorName = ref()
  const ourCompanyName = ref()
  const status = ref()*/
  const approveFlag = ref(false);
  const currentUserId = ref(0);

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([start, limit], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    const condition = {...store.getters['invoiceApplyList/getCondition']}
    store
        .dispatch('invoiceApplyList/search', {
          invoiceNo: condition.invoiceNo,
          storeName: condition.storeName,
          invoiceHead: condition.invoiceHead,
          orderNo: condition.orderNo,
          otherCompanyName: condition.otherCompanyName,
          oaWorkflowNo:condition.oaWorkflowNo,
          salesType:condition.salesType,
          creatorName:condition.creatorName,
          ourCompanyName:condition.ourCompanyName,
          status:condition.status,
          start: start.value,
          limit: limit.value,
          order_by: orderBy.value,
          order_desc: isSortDirDesc.value === true ? 'desc':'',
        })
        .then(response => {
          const data = response.data.data
          const list = data.ext.list
          listTotals.value = data.ext.totals
          if(data.ext.role_id === 12||data.ext.role_id  === 14){
            approveFlag.value = true;
          }else{
            approveFlag.value = false;
          }
          currentUserId.value = data.ext.current_user_id
          callback(list)
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '发票列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }

  return {
    searchList,
    approveFlag,
    currentUserId,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    orderBy,
    isSortDirDesc,
    refListTable,
    refetchData,
    /*invoiceNo,
    storeName,
    otherCompanyName,
    oaWorkflowNo,
    salesType,
    creatorName,
    ourCompanyName,
    status,*/
  }
}
